<template>
  <div class="px-4">
    <div class="pt-4 title">
      <h3>Create Account</h3>
    </div>

    <!-- loading -->
    <div v-if="!layouts.actives.loading">loading</div>
    <!-- input pwd -->
    <template v-else>
      <web-text-field
        label="Email"
        disabled
        v-model="dataSources.current.email"
      >
      </web-text-field>
      <web-text-field
        label="Enter Password"
        :append-icon="layouts.actives.showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showPwd ? 'text' : 'password'"
        v-model="dataSources.current.password"
        @click:append="layouts.actives.showPwd = !layouts.actives.showPwd"
      >
      </web-text-field>
      <web-text-field
        label="Confirm Password"
        :append-icon="layouts.actives.showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showConfirmPwd ? 'text' : 'password'"
        v-model="dataSources.current.confirmPassword"
        @click:append="layouts.actives.showConfirmPwd = !layouts.actives.showConfirmPwd"
      >
      </web-text-field>

      <v-card flat height="190">
        <check-password-strength
          v-model="dataSources.current.password"
          :confirmPassword="dataSources.current.confirmPassword"
          @strengthResult="updateStrengthResult"
        ></check-password-strength>
      </v-card>

      <web-btn
        class="mt-4 width-full"
        :disabledControl="!(dataSources.current.passwordStrength
        && dataSources.current.password === dataSources.current.confirmPassword
        && assists.tool.isNotEmpty(dataSources.current.password)
        && assists.tool.isNotEmpty(dataSources.current.confirmPassword))"
        @click="clickCreateAccount">
        Create Account
      </web-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Already have an account? <a class="f-w-600" @click="$router.push('/sign-in')">Sign In</a>
      </div>
    </template>
  </div>
</template>

<script>
import WebTextField from '@/components/base/WebTextField'
import WebBtn from '../../components/base/WebBtn.vue'
import { Tool, Crypto, Popup } from '@/assets/js/util'
import { AccountApi } from '@/api'
import CheckPasswordStrength from '@/components/common/CheckPasswordStrength'

export default {
  components: { WebTextField, WebBtn, CheckPasswordStrength },
  data () {
    return {
      assists: {
        tool: new Tool(),
        popup: new Popup()
      },
      dataSources: {
        current: {
          errorMsg: '',
          email: '',
          password: '',
          confirmPassword: '',
          passwordStrength: false
        }
      },
      layouts: {
        actives: {
          loading: true,
          showPwd: false,
          showConfirmPwd: false
        }
      }
    }
  },
  created () {
    const routeParam = this.$route.path
    // 暂存路径，方便域名切换时跳转
    this.$store.commit('setRedirectUrlParameter', routeParam)

    const resource = this.$route.params?.resource
    if (this.assists.tool.isNotEmpty(resource)) {
      AccountApi.parseLinkResource(resource, (res) => {
        this.dataSources.current.email = res.email
      }, (err) => {
        this.assists.popup.popupForError(err.message)
      })
    }
  },
  methods: {
    updateStrengthResult (result) {
      this.dataSources.current.passwordStrength = result
    },
    clickCreateAccount () {
      const { email, password } = this.dataSources.current

      if (this.assists.tool.isEmpty(email)) {
        this.assists.popup.popupForError(
          'Email is required.'
        )
        return
      }

      AccountApi.createAccount(
        {
          resource: this.$route.params?.resource,
          password: Crypto.md5(password)
        },
        () => {
          this.assists.popup.popupForSuccess(
            'Congratulations, your account has been created successfully!',
            () => this.$router.push('/sign-in')
          )
        },
        (err) => {
          this.assists.popup.popupForError(
            err.message
          )
        })
    }
  }
}
</script>

<style lang='sass' scoped>
.title
  height: 7vh
</style>
