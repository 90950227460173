<template>
  <div class="check-password-strength">
    <p class="introduction-title f-w-700">Your password must have:</p>
    <div class="m-t-10">
      <v-icon
        :color="layouts.data.passwordCheck.lengthMatch ? 'success' : 'error'"
        size="20"
      >
        {{ layouts.data.passwordCheck.lengthMatch ? 'check_circle' : 'cancel' }}
      </v-icon>
      <span class="f-w-300 f-s-12 m-l-5 password-have">8 or more characters</span>
    </div>
    <div class="m-t-10">
      <v-icon
        :color="layouts.data.passwordCheck.lowerMatch && layouts.data.passwordCheck.upperMatch ? 'success' : 'error'"
        size="20"
      >
        {{ layouts.data.passwordCheck.lowerMatch && layouts.data.passwordCheck.upperMatch ? 'check_circle' : 'cancel' }}
      </v-icon>
      <span class="f-w-300 f-s-12 m-l-5 password-have">Upper & Lowercase letters</span>
    </div>
    <div class="m-t-10">
      <v-icon :color="layouts.data.passwordCheck.numberMatch ? 'success' : 'error'" size="20">
        {{ layouts.data.passwordCheck.numberMatch ? 'check_circle' : 'cancel' }}
      </v-icon>
      <span class="f-w-300 f-s-12 m-l-5 password-have">At least one number</span>
    </div>
    <div
      v-if="layouts.data.passwordCheck.lengthMatch && (layouts.data.passwordCheck.upperMatch && layouts.data.passwordCheck.lowerMatch) && layouts.data.passwordCheck.numberMatch"
    >
      <div class="m-t-20 f-w-700">
        Strength:
        <span v-if="layouts.data.passwordCheck.percent <= 50" class="m-l-10 text-danger">Weak</span>
        <span v-if="layouts.data.passwordCheck.percent > 50 && layouts.data.passwordCheck.percent <= 75"
              class="m-l-10 text-warning">Moderate</span>
        <span v-if="layouts.data.passwordCheck.percent > 75" class="m-l-10 text-green-600">Strong</span>
      </div>
      <v-progress-linear
        :value="layouts.data.passwordCheck.percent"
        :color="layouts.data.passwordCheck.percent <= 50 ? '#F44336' : (layouts.data.passwordCheck.percent <= 75 ? '#FF9800' : '#43A047')"
        class="m-t-10"
        rounded
      ></v-progress-linear>
      <div
        v-if="layouts.data.passwordNotMatch"
        class="m-t-5 f-s-12 f-w-300 text-danger"
      >The passwords you entered do not match.
      </div>
      <div
        v-else-if="layouts.data.passwordCheck.repeat"
        class="m-t-5 f-s-12 f-w-300 text-danger"
      >Too many consecutive identical characters.
      </div>
      <div
        v-else-if="layouts.data.passwordCheck.percent === 50"
        class="m-t-5 f-s-12 f-w-300 text-danger"
      >
        This password is not secure. Try a different password.
      </div>
      <div
        v-else-if="!layouts.data.passwordCheck.repeat && layouts.data.passwordCheck.percent > 50"
        class="m-t-10 f-s-12 f-w-300"
      >Avoid using a password that you use with other websites or that might be easy for someone else to guess
      </div>
    </div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'check-password-strength',
  props: {
    value: {
      type: String,
      default: ''
    },
    confirmPassword: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    layouts: {
      data: {
        password: '',
        passwordNotMatch: false,
        passwordCheck: {
          percent: 0,
          lengthMatch: false,
          upperMatch: false,
          lowerMatch: false,
          numberMatch: false,
          repeat: false
        }
      }
    }
  }),
  watch: {
    value () {
      this.layouts.data.password = this.value
      this.comparePassword()
      this.onCheckPassword()
    },
    confirmPassword () {
      this.comparePassword()
    }
  },
  mounted () {
    this.layouts.data.password = this.value
    this.onCheckPassword()
  },
  methods: {
    comparePassword () {
      this.layouts.data.passwordNotMatch = tool.isNotEmpty(this.value) && tool.isNotEmpty(this.confirmPassword) && this.value !== this.confirmPassword
      this.$emit('strengthResult', (!this.layouts.data.passwordCheck.repeat) && (this.layouts.data.passwordCheck.percent >= 50) && !this.layouts.data.passwordNotMatch)
    },
    onCheckPassword () {
      if (tool.isEmpty(this.layouts.data.password)) {
        this.layouts.data.passwordCheck = {
          percent: 0,
          lengthMatch: false,
          upperMatch: false,
          lowerMatch: false,
          numberMatch: false,
          repeat: false
        }
        return
      }
      this.layouts.data.passwordCheck.lengthMatch = this.layouts.data.password.length >= 8
      this.layouts.data.passwordCheck.upperMatch = /[A-Z]+/.test(this.layouts.data.password)
      this.layouts.data.passwordCheck.lowerMatch = /[a-z]+/.test(this.layouts.data.password)
      this.layouts.data.passwordCheck.numberMatch = /\d+/.test(this.layouts.data.password)

      if (this.layouts.data.passwordCheck.lengthMatch && this.layouts.data.passwordCheck.upperMatch && this.layouts.data.passwordCheck.lowerMatch && this.layouts.data.passwordCheck.numberMatch) {
        this.layouts.data.passwordCheck.percent = this.layouts.data.password.length < 10 ? 50 : (this.layouts.data.password.length < 14 ? 75 : 100)
      } else {
        this.layouts.data.passwordCheck.percent = 25
      }

      this.layouts.data.passwordCheck.repeat = /(.)\1{2}/.test(this.layouts.data.password)
      this.$emit('strengthResult', (!this.layouts.data.passwordCheck.repeat) && (this.layouts.data.passwordCheck.percent >= 50) && !this.layouts.data.passwordNotMatch)
    }
  }
}
</script>

<style lang="scss" scoped>
  .introduction-title {
    color: #000410;
  }

  .password-have {
    color: #959595;
  }

  .check-password-strength .v-progress-linear {
    height: 4px !important;
  }
</style>
